<template>
  <div class="content">
    <div v-if="ShowRouterTabs">
      <RouterTabs v-model="activeName" :tabArr="tabs" />
    </div>
    <router-view />
  </div>
</template>

<script>
import RouterTabs from "@/base/components/Default/RouterTabs.vue";
export default {
  data() {
    return {
      ShowRouterTabs: true, // 控制tab栏显示隐藏
      listArr: ["cycBasisConfig", "CommentConfig"], // 带tab栏的页面路由name
      activeName: "cycBasisConfig", //当前选中tab
      // tab配置
      tabs: [
        // { label: "基础配置", name: "cycBasisConfig" },
        // { label: "心得、互动评论配置", name: "CommentConfig" },
      ],
    };
  },
  computed: {
    routeName() {
      return this.$route.name;
    },
  },
  created() {
    this.ShowRouterTabs = this.listArr.includes(this.routeName) ? true : false;
  },
  watch: {
    routeName(val) {
      this.ShowRouterTabs = this.listArr.includes(val) ? true : false;
      this.activeName = val;
    },
  },
  components: { RouterTabs },
};
</script>

<style lang="scss" scoped>
.content {
}
</style>
